import React, { ReactNode, ErrorInfo } from 'react'
import { message } from 'antd'
import { datadogRum } from '@datadog/browser-rum'

import { ErrorBoundary as ErrorBoundaryView } from '@pepper/ui'
import { getUserId } from '@pepper/utils/lib/api/functions/authHelpers'
import { fetchUser } from '@pepper/utils/lib/api/users/users'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
    user: {
      id: '',
      fullName: '',
      email: '',
      avatar: ''
    }
  }

  getUser = async (): Promise<{
    id: string
    fullName: string
    email: string
    avatar: string
  } | null> => {
    try {
      const userId = getUserId()

      if (userId) {
        const { data: user } = await fetchUser({ id: userId })

        if (user) {
          return {
            id: user.uid,
            fullName: user.full_name as string,
            email: user.email,
            avatar: user.avatar || ''
          }
        }
      }

      return null
    } catch (error) {
      return null
    }
  }

  componentDidMount = async (): Promise<void> => {
    try {
      const user = await this.getUser()

      if (user) {
        this.setState(prevState => {
          return {
            ...prevState,
            user: {
              id: user.id,
              fullName: user.fullName,
              email: user.email,
              avatar: user.avatar
            }
          }
        })
      }
    } catch (error) {
      if (error.message) {
        message.error(error.message)
      }
    }
  }

  static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  componentDidCatch = async (
    error: Error,
    errorInfo: ErrorInfo
  ): Promise<void> => {
    const user = await this.getUser()

    if (user) {
      datadogRum.setUser(user)
      datadogRum.addError(error, errorInfo)
    } else {
      datadogRum.addError(error, errorInfo)
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorBoundaryView user={this.state.user} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
