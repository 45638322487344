import { useState, useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Formik } from 'formik'
import { string as YupString, object as YupObject } from 'yup'
import { isFuture } from 'date-fns'

import { Modal, useShowMessage } from '@pepper/ui'

import { updateCustomerDetails } from '@pepper/utils/lib/api/customer-management/customer-management'

import {
  SelectField,
  TextField,
  DatePickerField,
} from 'components/FormFields/FormFields'

import styles from './UpdateCustomerModal.module.css'

const ORGANIZATIONS = [
  {
    label: 'Pepper Content Private Limited',
    value: 'pepper_content_private_limited',
  },
  {
    label: 'Pepper Content Global Private Limited',
    value: 'pepper_content_global_private_limited',
  },
  { label: 'Pepper Content Inc.', value: 'pepper_content_inc' },
]

type Props = {
  accountId: string
  organizationName?: string
  refetchOrganizationsList: () => void
}

type FormValues = {
  onboarded_on: string
  vendor_id: string
  pepper_organization: string
}

const formInitialValues = {
  onboarded_on: '',
  vendor_id: '',
  pepper_organization: '',
}

const UpdateCustomerModal = NiceModal.create<Props>(
  ({ accountId, organizationName, refetchOrganizationsList }) => {
    const { visible: isModalVisible, remove: removeModal } = useModal()
    const showMessage = useShowMessage()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = useCallback(
      async (values: FormValues) => {
        const { onboarded_on, vendor_id, pepper_organization } = values
        setIsSubmitting(true)
        const response = await updateCustomerDetails({
          vendorId: vendor_id,
          accountId,
          pepperOrganization: pepper_organization,
          onboardedOn: onboarded_on,
        })
        setIsSubmitting(false)
        if (response.status === 'ok') {
          refetchOrganizationsList()
          showMessage({
            type: 'success',
            message: `Customer details updated successfully.`,
          })
        } else {
          showMessage({
            type: 'error',
            message: response.error || 'Something went wrong',
          })
        }
      },
      [accountId, refetchOrganizationsList, showMessage]
    )

    return (
      <Formik<FormValues>
        initialValues={formInitialValues}
        onSubmit={async values => {
          await handleSubmit(values)
          removeModal()
        }}
        enableReinitialize={true}
        validationSchema={YupObject({
          onboarded_on: YupString()
            .trim()
            .required('Please enter Onboarded On date.'),
          vendor_id: YupString().trim().required('Please enter Zoho ID.'),
          pepper_organization: YupString()
            .trim()
            .required('Please select Billing Entity.'),
        })}
      >
        {(props): React.ReactNode => {
          return (
            <Modal
              title={organizationName}
              visible={isModalVisible}
              closable={true}
              centered={true}
              className={styles.updateCustomerModal}
              onOk={props.submitForm}
              onCancel={() => {
                removeModal()
              }}
              okButtonProps={{
                disabled: isSubmitting,
              }}
              okText="Update"
            >
              <SelectField
                name="pepper_organization"
                label="Select Billing Entity"
                setFieldValue={props.setFieldValue}
                setFieldTouched={props.setFieldTouched}
                required={true}
                fieldProps={{
                  options: ORGANIZATIONS,
                }}
              />
              <TextField
                name="vendor_id"
                label="Zoho ID"
                setFieldTouched={props.setFieldTouched}
                required={true}
                fieldProps={{}}
              />
              <DatePickerField
                name="onboarded_on"
                label="Onboarded On"
                required={true}
                fieldProps={{
                  disabledDate: current => {
                    return current && isFuture(current)
                  },
                  className: styles.width100,
                }}
              />
            </Modal>
          )
        }}
      </Formik>
    )
  }
)

export default UpdateCustomerModal
