import NiceModal from '@ebay/nice-modal-react'

import CreateNewTemplateModal from 'components/Modals/CreateDeleteTemplate/CreateTemplateModal'
import DeleteTemplate from 'components/Modals/CreateDeleteTemplate/DeleteTemplate'
import InvoiceSummary from 'container/Payments/components/Invoices/InvoiceSummary'
import AddLineItemModal from 'components/Modals/Invoice/AddLineItemModal'
import ReferenceCheckModal from 'components/Modals/ReferenceCheckModal/ReferenceCheckModal'
import UpdateCustomerModal from 'components/Modals/CustomerManagement/UpdateCustomerModal'

export const registerModals = (): void => {
  NiceModal.register('create-new-template', CreateNewTemplateModal)
  NiceModal.register('delete-template', DeleteTemplate)
  NiceModal.register('invoice-summary', InvoiceSummary)
  NiceModal.register('add-line-item', AddLineItemModal)
  NiceModal.register('reference-check', ReferenceCheckModal)
  NiceModal.register('update-customer-details', UpdateCustomerModal)
}
