import NiceModal, { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import * as Yup from 'yup'

import { Modal, useShowMessage } from '@pepper/ui'
import { deleteTemplate } from '@pepper/utils/lib/api/editor/editor'

import styles from './_.module.css'

type CreateNewTemplateModalProps = { templateId: string; refetch: () => void }

export const validationSchema: Yup.ObjectSchema[] = [
  Yup.object({
    templateName: Yup.string().required(),
  }),
]

const CreateTemplateModal = NiceModal.create<CreateNewTemplateModalProps>(
  ({ templateId, refetch }) => {
    const modal = useModal()
    const showMessage = useShowMessage()
    const handleDeleteTemplate = async (templateId: string) => {
      const { status, error } = await deleteTemplate({
        id: templateId,
      })
      if (status === 'ok') {
        showMessage({
          type: 'success',
          message: 'Template removed successfully',
        })
        refetch()
      } else {
        showMessage({
          type: 'error',
          message: error,
        })
      }
    }

    return (
      <Modal
        closable
        centered={true}
        okText="Delete"
        onOk={() => {
          handleDeleteTemplate(templateId)
          modal.remove()
        }}
        onCancel={() => modal.remove()}
        visible={modal.visible}
      >
        <div className={clsx(styles.modalDiv)}>
          <h1>Delete Template</h1>
          <div>
            Are you sure? You or the customer will not be able to use this
            template for any new project assignments.
          </div>
        </div>
      </Modal>
    )
  }
)

export default CreateTemplateModal
