import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Form, Formik, FormikProps, FormikHelpers } from 'formik'
import { getFieldComponent } from 'components/FormFields/FormFields'

import { createTemplates } from '@pepper/utils/lib/api/editor-templates/editor-templates'

import * as Yup from 'yup'

import { Modal, useShowMessage } from '@pepper/ui'

import styles from './_.module.css'

type CreateNewTemplateModalProps = { companyId: string }

export const validationSchema: Yup.ObjectSchema = Yup.object({
  templateName: Yup.string()
    .trim()
    .max(250, 'Please enter template name within 250 letters')
    .required('Please enter template name'),
})

const CreateNewTemplateModal = NiceModal.create<CreateNewTemplateModalProps>(
  ({ companyId }) => {
    const showMessage = useShowMessage()
    const modal = useModal()
    const submitForm = async (
      { templateName }: { templateName: string },
      formikActionProps: FormikHelpers<{
        templateName: string
      }>
    ) => {
      const { data, error, status } = await createTemplates({
        companyId,
        templateName,
      })
      if (status === 'ok') {
        modal.hide()
        setTimeout(() => {
          formikActionProps.resetForm()
          window.open(`/template/${data.template_id}`, '_blank')
        }, 1000)
      } else {
        showMessage({
          type: 'error',
          message: error,
        })
      }
    }

    return (
      <Formik
        initialValues={{ templateName: '' }}
        onSubmit={(values, action) => submitForm(values, action)}
        validationSchema={validationSchema}
      >
        {(props: FormikProps<{ templateName: string }>): React.ReactNode => {
          return (
            <Modal
              closable
              centered={true}
              okText="Create"
              title={'Create new template'}
              onOk={() => {
                if (!props.isSubmitting) {
                  props.submitForm()
                }
              }}
              okButtonProps={{
                htmlType: 'submit',
                disabled: props.dirty && !props.isValid,
              }}
              onCancel={() => {
                props.resetForm()
                modal.remove()
              }}
              visible={modal.visible}
              className={styles.modalDiv}
            >
              <Form>
                {getFieldComponent({
                  type: 'text',
                  name: 'templateName',
                  label: 'Give it a name',
                  // className: styles.anyClassName,
                  required: true,
                  actions: props,
                  fieldProps: {
                    placeholder: 'Ex. Website content template',
                  },
                })}
              </Form>
            </Modal>
          )
        }}
      </Formik>
    )
  }
)

export default CreateNewTemplateModal
