import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { Plus } from 'react-feather'

import { Drawer, useInvoice, Table, Button, Spin } from '@pepper/ui'

import { CURRENCY_SYMBOLS } from '@pepper/utils/lib/constant/enum/currency_symbols'

import styles from './Invoices.module.css'

type Props = {
  invoiceId: string
}

const columns = [
  {
    key: 'assignment_id',
    title: 'Assignment ID',
    dataIndex: 'task_id',
    align: 'center' as const,
    width: 140,
  },
  {
    key: 'assignment_name',
    title: 'Assignment Name',
    dataIndex: 'title',
    align: 'center' as const,
    width: 200,
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    width: 200,
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: ['item_amount', 'amount'],
    align: 'center' as const,
    width: 140,
    render: (amount: string, record: any) => {
      return `${
        CURRENCY_SYMBOLS[record?.item_amount?.currency as CurrencyKeys]?.symbol
      }${amount}`
    },
  },
]

const InvoiceSummary = NiceModal.create<Props>(({ invoiceId }) => {
  const { visible: isDrawerVisible, remove: removeDrawer } = useModal()
  const { show: showAddLineItemModal } = useModal('add-line-item')
  const {
    data: invoiceData,
    isLoading: isInvoiceDataLoading,
    refetch: refetchInvoiceData,
  } = useInvoice({ invoiceId })
  return (
    <Drawer
      title={null}
      placement="right"
      visible={isDrawerVisible}
      key="invoice_summary_drawer"
      width={770}
      maskClosable={true}
      onClose={() => {
        removeDrawer()
      }}
      closable={false}
      destroyOnClose={true}
      className={styles.invoiceSummaryDrawer}
    >
      {isInvoiceDataLoading ? (
        <div className={styles.loader}>
          <Spin
            indicator={
              <Loading3QuartersOutlined
                style={{ fontSize: 32, color: '#1600E5 ' }}
                spin
              />
            }
          />
          <div className={styles.loaderText}>
            Please wait while we fetch the invoice data.
          </div>
        </div>
      ) : (
        <>
          <div className={styles.invoiceCompanyStats}>
            <div className={styles.invoiceSalesStats}>
              <span className={styles.invoiceCompanyName}>
                {invoiceData?.account?.company_name}
              </span>
              <span> Customer POC: {invoiceData?.account?.name}</span>
              <span> Customer POC Email: {invoiceData?.account?.email}</span>
            </div>
            <div className={styles.pocInfo}>
              <span className={styles.justifySelfEnd}>Sales Rep:</span>
              <span className={styles.justifySelfEnd}>
                {invoiceData?.sales_person}
              </span>
              <span className={styles.justifySelfEnd}>Account Manager:</span>
              <span className={styles.justifySelfEnd}>
                {invoiceData?.account_manager}
              </span>
              <span className={styles.justifySelfEnd}>
                Customer Success Rep:
              </span>
              <span className={styles.justifySelfEnd}>
                {invoiceData?.customer_success_representative}
              </span>
            </div>
          </div>
          <Table<any>
            className={styles.invoiceTable}
            rowKey="source_line_item_id"
            loading={isInvoiceDataLoading}
            dataSource={invoiceData?.line_item ?? []}
            columns={columns}
            tableLayout="fixed"
            pagination={false}
          />
          <div className={styles.invoiceSummary}>
            {invoiceData?.status === 'draft' ? (
              <Button
                type="primary"
                className={styles.addLineItemButton}
                onClick={() =>
                  showAddLineItemModal({
                    invoiceId,
                    companyId: invoiceData?.account?.company_id,
                    refetchInvoiceData: refetchInvoiceData,
                  })
                }
              >
                <Plus size={16} />
                Line Item
              </Button>
            ) : null}
            <div className={styles.invoiceTotalStats}>
              <span className={styles.buttonWrap}>
                <span>Total Line Items:</span>
                <span className={styles.invoiceStatValue}>
                  {invoiceData?.line_item_count}
                </span>
              </span>
              <span className={styles.buttonWrap}>
                <span>Sub Total:</span>
                <span className={styles.invoiceStatValue}>
                  {`${
                    CURRENCY_SYMBOLS[invoiceData?.currency as CurrencyKeys]
                      ?.symbol
                  }${invoiceData?.sub_total}`}
                </span>
              </span>
              {invoiceData?.igst > 0 ? (
                <span className={styles.buttonWrap}>
                  <span>IGST{`(${invoiceData?.igst}%):`}</span>
                  <span className={styles.invoiceStatValue}>
                    {`${
                      CURRENCY_SYMBOLS[invoiceData?.currency as CurrencyKeys]
                        ?.symbol
                    }${invoiceData?.igst_amount}`}
                  </span>
                </span>
              ) : null}
              {invoiceData?.cgst > 0 ? (
                <span className={styles.buttonWrap}>
                  <span>CGST{`(${invoiceData?.cgst}%):`}</span>
                  <span className={styles.invoiceStatValue}>
                    {`${
                      CURRENCY_SYMBOLS[invoiceData?.currency as CurrencyKeys]
                        ?.symbol
                    }${invoiceData?.cgst_amount}`}
                  </span>
                </span>
              ) : null}
              {invoiceData?.sgst > 0 ? (
                <span className={styles.buttonWrap}>
                  <span>SGST{`(${invoiceData?.sgst}%):`}</span>
                  <span className={styles.invoiceStatValue}>
                    {`${
                      CURRENCY_SYMBOLS[invoiceData?.currency as CurrencyKeys]
                        ?.symbol
                    }${invoiceData?.sgst_amount}`}
                  </span>
                </span>
              ) : null}
              <span className={styles.buttonWrap}>
                <span>Total Amount:</span>
                <span className={styles.invoiceStatValue}>
                  {`${
                    CURRENCY_SYMBOLS[invoiceData?.currency as CurrencyKeys]
                      ?.symbol
                  }${invoiceData?.total}`}
                </span>
              </span>
            </div>
          </div>
        </>
      )}
    </Drawer>
  )
})

export default InvoiceSummary
