import React, { lazy, Suspense, useState, useEffect } from 'react'
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import NiceModal from '@ebay/nice-modal-react'

import { Spin, GTMPageView, useShowMessage } from '@pepper/ui'

import ErrorBoundary from './ErrorBoundary'

import { checkAuthorization } from '@pepper/utils/lib/api/functions/authHelpers'
import { checkTokenStatus } from '@pepper/utils/lib/api/functions/refreshToken'

const Dashboard = lazy(() => import('container/Dashboard/Dashboard'))
const Document = lazy(() => import('container/Content/Content'))
const Templates = lazy(() => import('container/Templates/Templates'))

const publicRoutes = [
  {
    path: '/404',
    exact: true,
    component: lazy(() => import('components/Placeholder')),
  },
  {
    path: '/500',
    exact: true,
    component: lazy(() => import('components/Placeholder')),
  },
  {
    path: '/login',
    exact: true,
    component: lazy(() => import('container/Login/Login')),
  },
  {
    path: '/forgot-password',
    exact: true,
    component: lazy(() => import('container/ForgotPassword/ForgotPassword')),
  },
]

const PrivateRoute: React.FC<{
  path: string
}> = ({ children, path }) => {
  const location = useLocation()
  const history = useHistory()
  const [isAuthorized, setIsAuthorized] = useState<'ok' | 'error'>('ok')
  const showMessage = useShowMessage()

  useEffect(() => {
    checkTokenStatus()
  }, [])

  useEffect(() => {
    const { status, error } = checkAuthorization()
    if (status === 'error') {
      showMessage({ type: 'error', message: error })
    }
    setIsAuthorized(status)
  }, [history, showMessage])

  return (
    <>
      {isAuthorized === 'ok' ? (
        <Route path={path}>{children}</Route>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location },
          }}
        />
      )}
    </>
  )
}

const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Spin className="spinner-full" />}>
        <Router>
          <GTMPageView />
          <QueryParamProvider ReactRouterRoute={Route}>
            <NiceModal.Provider>
              <Switch>
                {publicRoutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))}
                <PrivateRoute path="/document/:id">
                  <Document />
                </PrivateRoute>
                <PrivateRoute path="/template/:templateId">
                  <Templates />
                </PrivateRoute>
                <PrivateRoute path="/">
                  <Dashboard />
                </PrivateRoute>
              </Switch>
            </NiceModal.Provider>
          </QueryParamProvider>
        </Router>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Routes
