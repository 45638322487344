import React, { useEffect, useState } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button } from 'antd'
import { Modal, useShowMessage, ReferenceItem } from '@pepper/ui'
import { Form, Formik, FormikProps, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { getFieldComponent } from 'components/FormFields/FormFields'

import { getReferences } from '@pepper/utils/lib/api/editor/editor'

import { ReactComponent as RefCommentIcon } from 'assets/images/ref_comment.svg'
import { ReactComponent as ContraIcon } from 'assets/images/ref_contra.svg'
import { ReactComponent as SupportiveIcon } from 'assets/images/ref_support.svg'
import { ReactComponent as NeutralIcon } from 'assets/images/ref_neutral.svg'
import { ReactComponent as LoaderIcon } from 'assets/images/loader.svg'
import { ReactComponent as WhiteLoaderIcon } from 'assets/images/white_loader.svg'

import styles from './_.module.css'

type ReferenceCheckModalProps = {
  showCommentsInput: (val: boolean) => void
  setInputText: (val: string) => void
  referenceTextInitial: string
  assignmentId: string
}

type ReferenceArrayElement = {
  score: number
  snippet: string
  title: string
  type: string
  url: string
}

const validationSchema = Yup.object({
  referenceText: Yup.string()
    .required('Please add content to check for reference')
    .trim()
    .test(
      'minwordcount',
      'Please enter atleast 3 words to check for reference',
      val => Number(val?.split(' ').length) >= 3
    )
    .test(
      'maxwordcount',
      'Please keep the word count less than 50 to check for reference',
      val => Number(val?.split(' ').length) < 50
    ),
})

const ReferenceCheckModal = NiceModal.create<ReferenceCheckModalProps>(
  ({ showCommentsInput, setInputText, referenceTextInitial, assignmentId }) => {
    const modal = useModal()
    const showMessage = useShowMessage()
    const [refData, setRefData] = useState([])
    const [apiError, setApiError] = useState('')
    useEffect(() => {
      const fetchInitialTextRef = async () => {
        const { data, error, status } = await getReferences({
          id: assignmentId,
          claim: referenceTextInitial,
        })
        if (status === 'ok') {
          if (Number(data?.citations?.length) === 0) {
            setApiError('No reference found')
          }
          data.citations.sort(
            (a: ReferenceArrayElement, b: ReferenceArrayElement) =>
              a.score < b.score ? 1 : -1
          )
          setRefData(data.citations)
        } else {
          setApiError('error')
          showMessage({
            type: 'error',
            message: error,
          })
        }
      }
      fetchInitialTextRef()
    }, [assignmentId, referenceTextInitial, showMessage])
    const submitForm = async (
      { referenceText }: { referenceText: string },
      formikActionProps: FormikHelpers<{
        referenceText: string
      }>
    ) => {
      setRefData([])
      const { data, error, status } = await getReferences({
        id: assignmentId,
        claim: referenceText,
      })
      if (status === 'ok') {
        if (Number(data?.citations?.length) === 0) {
          setApiError('No reference found')
        }
        data.citations.sort(
          (a: ReferenceArrayElement, b: ReferenceArrayElement) =>
            a.score < b.score ? 1 : -1
        )
        setRefData(data.citations)
      } else {
        setApiError('error')
        showMessage({
          type: 'error',
          message: error,
        })
      }
    }
    return (
      <Formik
        initialValues={{ referenceText: referenceTextInitial }}
        onSubmit={(values, actions) => submitForm(values, actions)}
        validationSchema={validationSchema}
      >
        {(formik: FormikProps<{ referenceText: string }>): React.ReactNode => (
          <Modal
            title={'Reference checker'}
            closable
            centered={true}
            className={styles.modalDiv}
            visible={modal.visible}
            onCancel={() => {
              modal.remove()
            }}
            footer={null}
          >
            <div className={styles.headerRefChecker}>
              <Form>
                {getFieldComponent({
                  className: styles.getFieldComponent,
                  type: 'textarea',
                  name: 'referenceText',
                  label: 'Content to check',
                  required: true,
                  actions: formik,
                  fieldProps: {
                    placeholder: '',
                    autoSize: true,
                    className: styles.textArea,
                  },
                })}
              </Form>
              <Button
                type="primary"
                className={styles.refCheckButton}
                onClick={() => {
                  if (!formik.isSubmitting) {
                    formik.submitForm()
                    setApiError('')
                  }
                }}
              >
                {!refData.length && apiError === '' ? (
                  <div className={styles.loaderIconButtonContainer}>
                    <WhiteLoaderIcon className={styles.loaderIconButton} />
                  </div>
                ) : (
                  <div>Look for references</div>
                )}
              </Button>
              <hr className={styles.separation} />
            </div>
            {!refData.length && apiError === '' && (
              <div className={styles.refItemsLoaderWrap}>
                <div className={styles.loaderIconContainer}>
                  <LoaderIcon className={styles.loaderIcon} />
                </div>
                <div className={styles.loaderText}>
                  Please wait while we are looking for <br /> references related
                  to your content
                </div>
              </div>
            )}
            {apiError === 'No reference found' && (
              <div className={styles.errorMsg}>{apiError}</div>
            )}
            {refData.length > 0 && apiError === '' && (
              <div className={styles.refItemList}>
                <ReferenceItem
                  modalRemove={() => modal.remove()}
                  showCommentsInput={showCommentsInput}
                  setInputText={setInputText}
                  refData={refData}
                  commentIcon={<RefCommentIcon />}
                  supportIcon={<SupportiveIcon />}
                  neutralIcon={<NeutralIcon />}
                  contraIcon={<ContraIcon />}
                />
              </div>
            )}
          </Modal>
        )}
      </Formik>
    )
  }
)

export default ReferenceCheckModal
