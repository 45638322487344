import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum'

// * * antd.less is imported above App component to avoid antd overriding styles from css modules
import '@pepper/ui/src/styles/index.css'
import '@pepper/ui/src/styles/grid.css'
import './App.less'
// import './styles/index.css'

import { registerModals } from './modals'

import App from './App'

if (process.env.REACT_APP_DD_ENABLE === 'TRUE') {
  datadogRum.init({
    applicationId: '4a1a65ab-482d-4e58-9063-dff12a4654a3',
    clientToken: 'pub0cd5a9227731e5294bae4293bf1558b3',
    site: 'datadoghq.com',
    service: 'ops-portal',
    env: 'prod',
    sampleRate: 100,
    trackInteractions: true,
    version: process.env.REACT_APP_PACKAGE_VERSION || '0.0.0',
  })
}

registerModals()

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
)
