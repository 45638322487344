import React from 'react'

/* main router */
import Routes from './router'

import {
  AuthProvider,
  GlobalStateProvider,
  MessageProvider,
  UtilsProvider,
} from '@pepper/ui'

const App: React.FC = () => {
  return (
    <GlobalStateProvider>
      <MessageProvider>
        <AuthProvider>
          <UtilsProvider>
            <Routes />
          </UtilsProvider>
        </AuthProvider>
      </MessageProvider>
    </GlobalStateProvider>
  )
}

export default App
