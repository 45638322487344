import { useState, useCallback } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Formik } from 'formik'
import {
  string as YupString,
  number as YupNumber,
  object as YupObject,
} from 'yup'

import {
  Modal,
  useShowMessage,
  usePaginatedAssignmentList,
  Spin,
  Empty,
  PRESENTED_IMAGE_SIMPLE,
} from '@pepper/ui'

import { addLineItemsToInvoice } from '@pepper/utils/lib/api/invoices/invoices-v2'

import { getFieldComponent } from 'components/FormFields/FormFields'

import styles from './_.module.css'

type Props = {
  invoiceId: string
  companyId: string
  refetchInvoiceData: () => void
}

type FormValues = {
  amount: number
  description: string
  assignment_id?: string
  project_id?: string
}

const formInitialValues = { amount: 0, description: '' }

const AddLineItemModal = NiceModal.create<Props>(
  ({ invoiceId, refetchInvoiceData, companyId }) => {
    const { visible: isModalVisible, remove: removeModal } = useModal()
    const showMessage = useShowMessage()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { records: assignments, isLoading: isLoadingAssignments } =
      usePaginatedAssignmentList(
        '/assignments',
        {
          company_id: companyId,
        },
        undefined,
        false
      )

    const handleSubmit = useCallback(
      async (values: FormValues) => {
        const { amount, description, assignment_id } = values
        setIsSubmitting(true)
        const response = await addLineItemsToInvoice({
          invoiceId,
          lineItems: [
            { amount: Number(amount) * 100, description, assignment_id },
          ],
        })
        setIsSubmitting(false)
        if (response.status === 'ok') {
          refetchInvoiceData()
          showMessage({
            type: 'success',
            message: `Line Item added successfully.`,
          })
        } else {
          showMessage({
            type: 'error',
            message: response.error || 'Something went wrong',
          })
        }
      },
      [invoiceId, showMessage, refetchInvoiceData]
    )

    return (
      <Formik<FormValues>
        initialValues={formInitialValues}
        onSubmit={async values => {
          await handleSubmit(values)
          removeModal()
        }}
        enableReinitialize={true}
        validationSchema={YupObject({
          description: YupString().trim().required('Please enter Description.'),
          amount: YupNumber()
            .notOneOf([0], 'Please enter valid amount.')
            .typeError('Please enter valid amount.')
            .required('Please enter amount.'),
        })}
      >
        {(props): React.ReactNode => {
          return (
            <Modal
              title="Add Line Item"
              visible={isModalVisible}
              closable={true}
              centered={true}
              className={styles.feedbackModalSingle}
              onOk={props.submitForm}
              onCancel={() => {
                removeModal()
              }}
              okButtonProps={{
                disabled: isSubmitting,
              }}
              okText="Add Line Item"
            >
              {getFieldComponent({
                type: 'text',
                name: 'amount',
                label: `Amount`,
                required: true,
                actions: props,
              })}
              {getFieldComponent({
                type: 'textarea',
                name: 'description',
                label: 'Description',
                required: true,
                actions: props,
              })}
              {getFieldComponent({
                type: 'select',
                name: 'assignment_id',
                label: 'Assignment ID',
                placeholder: 'Please select Assignment ID',
                required: false,
                fieldProps: {
                  size: 'default',
                  notFoundContent: isLoadingAssignments ? (
                    <div className={styles.fetchLoader}>
                      <Spin size="small" />
                    </div>
                  ) : (
                    <div className={styles.empty}>
                      <Empty
                        image={PRESENTED_IMAGE_SIMPLE}
                        description="No assignments found for this company."
                      />
                    </div>
                  ),
                  showSearch: true,
                  allowClear: true,
                  options: (assignments ?? []).map(assignment => ({
                    label: `${assignment.assignment_code} - ${assignment.assignment_title}`,
                    value: assignment.id,
                  })),
                  showArrow: false,
                },
                actions: props,
              })}
            </Modal>
          )
        }}
      </Formik>
    )
  }
)

export default AddLineItemModal
