/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: refactor form fields component as done on business portal
import React, {
  ReactNode,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
  useRef,
} from 'react'
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikHelpers,
  useField,
} from 'formik'
import clsx from 'clsx'
import ReactHtmlParser from 'react-html-parser'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@peppercontent/ckeditor5-custom-build'
import { useModal } from '@ebay/nice-modal-react'
import { Form } from 'antd'

import {
  CheckCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  PlusOutlined,
  FacebookFilled,
  LinkedinFilled,
  TwitterSquareFilled,
  InstagramFilled,
  InfoCircleFilled,
  EyeOutlined,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import {
  Button,
  CheckboxGroup,
  CheckboxGroupProps,
  DatePicker,
  DatePickerProps,
  FileUpload,
  FileUploadProps,
  Input,
  InputProps,
  Password,
  RadioGroup,
  RadioGroupProps,
  Select,
  SelectProps,
  TextArea,
  TextAreaProps,
  // SliderProps,
  Slider as AntSlider,
  Switch,
  Rate,
  Tag,
  useUtils,
  CustomSelectDropdown,
  SelectDropdownGroupProps,
  CommentBox,
  AddInSelect,
  PopOver,
  Modal,
  DimensionsSelect,
  useLoomEmbedHtml,
  RichText,
  TemplateList,
  Tooltip,
} from '@pepper/ui'
import { TAGS } from '@pepper/utils/lib/constant/enum/tags'
import noop from '@pepper/utils/lib/function/noop'
import { Input as AntInput } from 'antd'
import styles from './_.module.css'

import BlogContentVerticalIcon from 'assets/images/blogContentVertical.svg'
import { ReactComponent as TooltipInfo } from 'assets/images/mini_tooltip.svg'
import { ReactComponent as TemplateIcon } from 'assets/images/template-icon.svg'
import { ReactComponent as PlusIcon } from 'assets/images/fi_plus.svg'
import { ReactComponent as EmptyIcon } from 'assets/images/empty_list.svg'

const { RangePicker } = DatePicker

interface FieldProps {
  name: string
  error?: boolean
  label?: React.ReactNode | string
  helperText?: React.ReactNode | string
  className?: string
  size?: SizeType
  showError?: boolean
  onBlur?: React.EventHandler<React.FocusEvent>
  helperErrorText?: string
  required?: boolean
  style?: React.CSSProperties
  placeholder?: string
  disabled?: boolean
  selectFieldName?: string
  showSwitchOptions?: boolean
  /**
   * Classname for inner fields to be used when there is a need.
   */
  additionalClassName?: string
}

export type FieldComponent =
  | 'text'
  | 'textarea'
  | 'password'
  | 'currency'
  | 'tel'
  | 'multi-select'
  | 'multi-select-tags'
  | 'select'
  | 'list'
  | 'file'
  | 'checkbox'
  | 'radio'
  | 'boolean'
  | 'selection'
  | 'datepicker'
  | 'rangepicker'
  | 'social'
  | 'blockSelect'
  | 'slider'
  | 'rate'
  | 'tag'
  | 'tagSelect'
  | 'customSelectDropdown'
  | 'comment-box'
  | 'addOptionSelect'
  | 'multiBlockSelect'
  | 'addCustomColors'
  | 'colorBlockSelect'
  | 'addDimensions'
  | string

export function getFieldComponent<FormValues>({
  type,
  name,
  label,
  helperText,
  className = '',
  fieldProps,
  required,
  actions,
  currencyType,
  placeholder,
}: {
  type: FieldComponent
  name: string
  label?: string | ReactNode
  helperText?: string | ReactNode
  className?: string
  fieldProps?: any
  placeholder?: string
  required?: boolean
  currencyType?: number
  actions: FormikHelpers<FormValues>
}): ReactNode {
  switch (type) {
    case 'text':
      return (
        <div className={styles.fieldContainer}>
          <TextField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )

    case 'textarea':
      return (
        <div className={styles.fieldContainer}>
          <TextAreaField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'password':
      return (
        <div className={styles.fieldContainer}>
          <PasswordField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'tel':
      return (
        <div className={styles.fieldContainer}>
          <PhoneField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'currency':
      return (
        <div className={styles.fieldContainer}>
          <CurrencyField
            currencyType={currencyType}
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'list':
      return (
        <div className={styles.fieldContainer}>
          <ListField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'radio':
      return (
        <div className={styles.fieldContainer}>
          <RadioField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'checkbox':
      return (
        <div className={styles.fieldContainer}>
          <CheckboxField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'select':
      return (
        <div className={styles.fieldContainer}>
          <SelectField
            name={name}
            placeholder={placeholder}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
            additionalClassName={fieldProps.additionalClassName}
          />
        </div>
      )
    case 'multi-select':
      return (
        <div className={styles.fieldContainer}>
          <SelectField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={{ ...fieldProps, mode: 'multiple' }}
          />
        </div>
      )
    case 'multi-select-tags':
      return (
        <div className={styles.fieldContainer}>
          <SelectField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={{ ...fieldProps, mode: 'tags' }}
          />
        </div>
      )
    case 'file':
    case 'uploadFile':
      return (
        <div className={styles.fieldContainer}>
          <FileUploadField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'selection':
      return (
        <div className={styles.fieldContainer}>
          <SelectionField name={name} className={className} {...fieldProps} />
        </div>
      )
    case 'datepicker':
      return (
        <div className={styles.fieldContainer}>
          <DatePickerField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'rangepicker':
      return (
        <div className={styles.fieldContainer}>
          <RangePickerField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            required={required}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'social':
      return (
        <div className={styles.fieldContainer}>
          <AddLinkField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'blockSelect':
      return (
        <div className={styles.fieldContainer}>
          <BlockSelect
            label={label}
            required={required}
            name={name}
            {...fieldProps}
          />
        </div>
      )
    case 'slider':
      return (
        <div className={styles.fieldContainer}>
          <Slider
            label={label}
            required={required}
            setFieldValue={actions.setFieldValue}
            name={name}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'switch':
    case 'boolean':
      return (
        <div className={styles.fieldContainer}>
          <SwitchField
            label={label}
            required={required}
            setFieldValue={actions.setFieldValue}
            name={name}
            className={className}
            additionalClassName={fieldProps?.additionalClassName}
            {...fieldProps}
          />
        </div>
      )
    case 'rate':
      return (
        <div className={styles.fieldContainer}>
          <RatingField
            name={name}
            label={label}
            className={className}
            setFieldValue={actions.setFieldValue}
            required={required}
          />
        </div>
      )
    case 'tag':
      return <TagsField name={name} />
    case 'html':
      return (
        <div className={styles.fieldContainer}>
          <RichEditor
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            required={required}
            setFieldValue={actions.setFieldValue}
            fieldProps={fieldProps}
          />
        </div>
      )

    case 'richTextComment':
      return (
        <RichCommentEditor
          name={name}
          label={label}
          className={className}
          helperText={helperText}
          required={required}
          setFieldValue={actions.setFieldValue}
          fieldProps={fieldProps}
        />
      )

    case 'tagSelect':
      return (
        <div className={styles.fieldContainer}>
          <TagMultiSelect
            label={label}
            required={required}
            name={name}
            className={className}
            {...fieldProps}
          />
        </div>
      )
    case 'customSelectDropdown':
      return (
        <div className={styles.fieldContainer}>
          <CustomSelectField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'comment-box':
      return (
        <div className={styles.fieldContainer}>
          <CommentBoxField
            name={name}
            label={label}
            className={className}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            required={required}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'addOptionSelect':
      return (
        <div className={styles.fieldContainer}>
          <AddOptionInSelect
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
          />
        </div>
      )
    case 'multiBlockSelect':
      return (
        <div className={styles.fieldContainer}>
          <MultiSelectBlock
            label={label}
            required={required}
            name={name}
            helperText={helperText}
            {...fieldProps}
          />
        </div>
      )
    case 'addCustomColors':
      return (
        <div className={styles.fieldContainer}>
          <AddMultipleCustomColors
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            required={required}
            fieldProps={fieldProps}
            hideCustomColorLabel={fieldProps.hideCustomColorLabel}
          />
        </div>
      )

    case 'colorBlockSelect':
      return (
        <div className={styles.fieldContainer}>
          <ColorBlocks
            label={label}
            required={required}
            name={name}
            helperText={helperText}
            {...fieldProps}
          />
        </div>
      )

    case 'selectDimensions':
      return (
        <div className={styles.fieldContainer}>
          <AddDimensionSelect
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            required={required}
            fieldProps={fieldProps}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
          />
        </div>
      )

    case 'addDimensions':
      return (
        <div className={styles.fieldContainer}>
          <DimensionsField
            name={name}
            label={label}
            className={className}
            helperText={helperText}
            setFieldValue={actions.setFieldValue}
            setFieldTouched={actions.setFieldTouched}
            popoverInfo={fieldProps.popoverInfo}
            fieldProps={fieldProps}
          />
        </div>
      )
    case 'custom_select_search':
      return (
        <div className={styles.customSelectsearchWrapper}>
          <CustomSelectSearch
            name={name}
            label={label}
            required={required}
            actions={actions}
            fieldProps={fieldProps}
          />
        </div>
      )

    case 'textgroup':
      return (
        <div className={styles.fieldContainer}>
          <TextGroupField
            name={name}
            className={className}
            label={label}
            helperText={helperText}
            required={required}
            setFieldTouched={actions.setFieldTouched}
            fieldProps={fieldProps}
            disabled={fieldProps.disabled}
            setFieldValue={actions.setFieldValue}
            placeholder={placeholder}
          />
        </div>
      )

    default: {
      throw Error(`${type} component not available`)
    }
  }
}

/* comment box */
interface CommentBoxFieldProps extends FieldProps {
  fieldProps: {
    className?: string
    showCount?: boolean
    maxLength?: number
  }
  setFieldValue: (n: string, v: any) => void
  setFieldTouched?: (n: string) => void
}

export const CommentBoxField: React.FC<CommentBoxFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched = noop,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const error: any = meta.error

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <CommentBox
          formData={field.value}
          onChange={(values: CommentBox) => {
            setFieldTouched(name)
            setFieldValue(name, values)
          }}
          showSendButton={false}
          {...fieldProps}
          className={styles.commentBoxContainer}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && error?.comment ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {error.comment}
        </div>
      ) : null}
    </div>
  )
}

/* rate */
interface RatingFieldProps extends FieldProps {
  setFieldValue: (n: string, v: number) => void
}

export const RatingField: React.FC<RatingFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  helperErrorText,
  showError = true,
  required,
  setFieldValue,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Rate
          value={field.value || 0}
          onChange={e => setFieldValue(name, e)}
          className={`${errorClass}`}
        />
      </label>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {helperErrorText && (
        <div className={styles.helperText}>
          {meta.error || field.value === '' ? (
            <CloseCircleFilled className={styles.red} />
          ) : (
            <CheckCircleFilled className={styles.green} />
          )}
          {helperErrorText}
        </div>
      )}
    </div>
  )
}

/* text */
interface TextFieldProps extends FieldProps {
  setFieldTouched: (n: string, v: boolean) => void
  fieldProps: InputProps
}

export const TextField: React.FC<TextFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  helperErrorText,
  showError = true,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Input {...field} {...fieldProps} className={`${errorClass}`} />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {helperErrorText && (
        <div className={styles.helperText}>
          {meta.error || field.value === '' ? (
            <CloseCircleFilled className={styles.red} />
          ) : (
            <CheckCircleFilled className={styles.green} />
          )}
          {helperErrorText}
        </div>
      )}
    </div>
  )
}

/* Text Field Group */
interface TextGroupProps extends FieldProps {
  setFieldTouched: (n: string, v: boolean) => void
  setFieldValue: (n: string, v: string) => void
  fieldProps: {
    inputFieldProps: InputProps
    selectFieldProps: SelectProps
    selectFieldName: string
    options: { label: string; value: string }[]
    prefix?: string
    type?: string
    defaultSelectValue?: string
  }
}

export const TextGroupField: React.FC<TextGroupProps> = ({
  label,
  required,
  name,
  helperText,
  showError,
  disabled,
  fieldProps,
  className,
  setFieldValue,
  placeholder,
}) => {
  const [inputField, inputMeta] = useField(name)
  const [selectField, selectMeta] = useField({
    name: fieldProps.selectFieldName,
    defaultValue: fieldProps.defaultSelectValue,
  })
  return (
    <div className={className}>
      <span className={styles.labelText}>
        {label}
        {required && <span className={styles.requiredText}>*</span>}
      </span>
      <AntInput.Group className={styles.antInputGroup}>
        <Select
          options={fieldProps.options}
          disabled={disabled}
          className={styles.textGroupSelect}
          value={selectField.value}
          defaultValue={fieldProps.defaultSelectValue}
          onChange={value => setFieldValue(fieldProps.selectFieldName, value)}
        />
        <Input
          type={fieldProps.type}
          placeholder={placeholder}
          {...inputField}
          name={name}
          disabled={disabled}
          prefix={fieldProps.prefix}
        />
      </AntInput.Group>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && selectMeta.touched && selectMeta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {selectMeta.error}
        </div>
      ) : inputMeta.touched && inputMeta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {inputMeta.error}
        </div>
      ) : null}
    </div>
  )
}

/* password */
interface PasswordFieldProps extends FieldProps {
  fieldProps: InputProps
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Password {...field} {...fieldProps} />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* tel */
interface PhoneFieldProps extends FieldProps {
  fieldProps: InputProps
  setFieldValue: (
    n: string,
    v: {
      extension: string | undefined
      number: string | undefined
    }
  ) => void
  setFieldTouched: (n: string, v: boolean) => void
}

export const PhoneField: React.FC<PhoneFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  required,
  helperText,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField<PhoneNumber | null>(name)
  const extension = field.value?.extension
  const number = field.value?.number
  const options = ['91']
  const selectBefore = (
    <Select
      className={styles.phoneExtensionSelect}
      options={options.map(option => ({ value: option, label: option }))}
      onChange={(value): void =>
        setFieldValue(name, {
          extension: value || '',
          number: number || '',
        })
      }
      value={extension}
    ></Select>
  )

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Input
          addonBefore={selectBefore}
          onBlur={(): void => setFieldTouched(name, true)}
          onChange={(e): void =>
            setFieldValue(name, {
              extension: extension || '',
              number: e.target.value || '',
            })
          }
          value={number}
          type="tel"
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* currency */
interface CurrencyFieldProps extends FieldProps {
  fieldProps: InputProps
  currencyType?: number
}

export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
  currencyType,
}) => {
  const [field, meta, helpers] = useField<PayScale>(name)
  const { currencies } = useUtils()
  if (currencyType) {
    field.value.currency = currencyType
  }
  // get the type of currency type base on the id passed
  // if no currency is passed default value is taken as paise (which is `1`)
  const currencyMeta =
    currencies.find(currency => currency.id === field.value?.currency) ??
    currencies[0]
  const currencyMultiplier = currencyMeta.multiplier ?? 1

  // get currency symbol (use parent currency symbol if available)
  let symbol = ''
  if (currencyMeta.parent_id) {
    symbol =
      currencies.find(currency => currencyMeta?.parent_id === currency.id)
        ?.symbol ?? ''
  } else {
    symbol = currencyMeta.symbol
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value ?? 0
    const amount = (parseFloat(value) * currencyMultiplier).toFixed(2)
    helpers.setValue({
      amount: parseFloat(amount),
      currency: currencyMeta?.id,
    })
  }

  const getCurrencyValue = (value: PayScale): number => {
    const amount = value.amount ?? 0
    return parseFloat(Number(amount / currencyMultiplier).toFixed(2))
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Input
          {...field}
          {...fieldProps}
          prefix={symbol ?? ''}
          onChange={handleInputChange}
          value={getCurrencyValue(
            field.value || { amount: 0, currency: 1, unit: 1 }
          )}
          step={0.01}
          min={0}
          type="number"
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* Rich Text */
interface RichTextFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  fieldProps: any
}

const editorConfiguration = {
  toolbar: [
    'heading',
    '|',
    'bold',
    'italic',
    'underline',
    '|',
    'link',
    '|',
    'bulletedList',
    'numberedList',
    '|',
    'undo',
    'redo',
    '|',
    'alignment',
  ],
  link: {
    defaultProtocol: 'http://',
    decorators: {
      openInNewTab: {
        mode: 'automatic',
        label: 'Open in a new tab',
        callback: (url: string) => url,
        defaultValue: true, // This option will be selected by default.
        attributes: {
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    },
  },
}

export const RichTextField = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
}: RichTextFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
      </label>
      <CKEditor
        {...fieldProps}
        editor={ClassicEditor}
        onChange={(e: any, editor: { getData: () => any }): void => {
          const data = editor.getData()
          setFieldValue(name, data)
        }}
        onReady={(editor: { setData: (val: string) => void }) => {
          if (field.value) {
            editor?.setData(field.value)
          }
        }}
        config={editorConfiguration}
        data={field.value ? field.value : ''}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

export const RichEditor = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
}: RichTextFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)

  const isAttachFile = fieldProps.isAttachFile
  const isVideoRecord = fieldProps.isVideoRecord
  const isLoomField = isAttachFile && isVideoRecord
  const isTextOnly = !isAttachFile && !isLoomField

  const updateFieldValue = (values: RichTextValues | string): void => {
    setFieldValue(name, values)
    if (fieldProps.tagField && typeof values !== 'string') {
      setFieldValue(fieldProps.tagField, values?.richText)
    }
  }

  const propsBasedOnEditingAllowedState = useMemo(() => {
    if (fieldProps.disabled) {
      return {
        shouldDisplayLoomURLs: false,
        isVideoRecord: false,
        isAttachFile: false,
      }
    }

    return {
      loomButtonId: name,
      shouldDisplayLoomURLs: true,
      isVideoRecord,
      isAttachFile,
    }
  }, [fieldProps.disabled, name, isAttachFile, isVideoRecord])

  const fieldValue: RichTextValues = useMemo(() => {
    if (field.value) return field.value
    if (isLoomField) return { richText: '', attachments: [], loomUrls: [] }
    if (isTextOnly) return ''
  }, [field.value, isLoomField, isTextOnly])

  return (
    <div className={clsx(className, styles.editorWrap)}>
      <label>
        <span
          className={clsx(styles.labelText, {
            [styles.inputLabelV2]: fieldProps?.loadV2,
          })}
        >
          {label}
          {required && <span className={styles.requiredText}>*</span>}
          {fieldProps.popoverInfo && (
            <PopOver content={fieldProps.popoverInfo}>
              <InfoCircleFilled className={styles.infoIcon} />
            </PopOver>
          )}
        </span>
      </label>
      <RichText
        {...fieldProps}
        placeholder={fieldProps.placeholder}
        onChange={updateFieldValue}
        fieldData={fieldValue}
        {...propsBasedOnEditingAllowedState}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
      {fieldProps.disabled
        ? fieldValue?.loomUrls?.map(loomUrl => (
            <LoomVideoThumbnail key={loomUrl} loomUrl={loomUrl} />
          ))
        : null}
    </div>
  )
}

export const RichCommentEditor = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
}: RichTextFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  const updateFieldValue = (
    values: RichTextValues | string,
    plainText?: number
  ): void => {
    if (typeof values !== 'string') {
      setFieldValue('commentDetails', {
        comment: values.richText || '',
        attachments: values.attachments || [],
        plainText: plainText || field?.value?.plainText,
      })
    }
  }

  const fieldValue: RichTextValues = useMemo(() => {
    return {
      richText: field?.value?.comment || '',
      attachments: field?.value?.attachments,
      loomUrls: [],
    }
  }, [field.value])

  return (
    <div className={clsx(className, styles.editorWrap)}>
      <label>
        <span
          className={clsx(styles.labelText, {
            [styles.inputLabelV2]: fieldProps?.loadV2,
          })}
        >
          {label}
          {required && <span className={styles.requiredText}>*</span>}
          {fieldProps.popoverInfo && (
            <PopOver content={fieldProps.popoverInfo}>
              <InfoCircleFilled className={styles.infoIcon} />
            </PopOver>
          )}
        </span>
      </label>
      <RichText
        {...fieldProps}
        placeholder={fieldProps.placeholder}
        onChange={updateFieldValue}
        fieldData={fieldValue}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

const LoomVideoThumbnail = ({ loomUrl }: { loomUrl: string }) => {
  const valueWithLoomEmbed = useLoomEmbedHtml(loomUrl)

  return <> {ReactHtmlParser(valueWithLoomEmbed)}</>
}

/* textarea */
interface TextAreaFieldProps extends FieldProps {
  fieldProps?: TextAreaProps
}

export const TextAreaField: React.FC<TextAreaFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta, helpers] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <TextArea
          {...field}
          {...fieldProps}
          onBlur={(): void => helpers.setTouched(true)}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* text list field  (on enter the text is be added to the list) */
interface ListFieldProps extends FieldProps {
  fieldProps?: InputProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
}

export const ListField: React.FC<ListFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const [value, setValue] = useState<string>('')

  const addItem = (event: any): void => {
    event.preventDefault()
    setFieldValue(name, [...field.value, event.target.value])
    setValue('')
  }

  const removeItem = (index: number): void => {
    const result = [
      ...field.value?.slice(0, index),
      ...field.value?.slice(index + 1),
    ]
    setFieldValue(name, result)
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Input
          onChange={(e): void => setValue(e.target.value)}
          onBlur={(): void => setFieldTouched(name, true)}
          onPressEnter={addItem}
          value={value}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      <ol className={styles.list}>
        {field.value?.map((item: string, index: number) => (
          <li key={index}>
            <span>
              {index + 1}.&nbsp;&nbsp;
              {item}
            </span>
            <Button size="small" type="text">
              <DeleteOutlined onClick={(): void => removeItem(index)} />
            </Button>
          </li>
        ))}
      </ol>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* selection field */
interface SelectionFieldProps extends FieldProps {
  options: {
    label: string
    value: string
    suffix?: string | React.ReactNode
    disabled?: boolean
  }[]
}

export const SelectionField: React.FC<SelectionFieldProps> = ({
  className = '',
  name,
  helperText,
  showError = true,
  options,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={`${className} ${styles.selectionField}`}>
      {options.map(option => (
        <label
          key={option.value}
          className={field.value === option.value ? styles.selected : ''}
        >
          {!option.disabled && (
            <>
              {field.value === option.value ? (
                <CheckCircleFilled />
              ) : (
                <CheckCircleOutlined />
              )}
            </>
          )}
          <input
            className={styles.visiblityHidden}
            {...field}
            type="radio"
            value={option.value}
            disabled={option.disabled}
          />
          <div className={styles.labelValue}>
            <span>{option.label}</span> <span>{option.suffix}</span>
          </div>
        </label>
      ))}
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* select field */
interface SelectFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
  fieldProps: SelectProps
}

export const SelectField: React.FC<SelectFieldProps> = ({
  className = '',
  label,
  name,
  required,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  placeholder,
  fieldProps,
  additionalClassName,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''

  return (
    <div className={`${className} ${styles.selectField}`}>
      <label className={additionalClassName}>
        <span className={styles.labelText}>
          {label} {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Select
          placeholder={placeholder}
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={(value): void => setFieldValue(name, value)}
          onBlur={(): void => setFieldTouched(name, true)}
          value={field.value}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* radio field */
interface RadioFieldProps extends FieldProps {
  fieldProps: RadioGroupProps
}

export const RadioField: React.FC<RadioFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <RadioGroup {...field} {...fieldProps} />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* radio field */
interface CheckboxFieldProps extends FieldProps {
  fieldProps: CheckboxGroupProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
  isMarginDisabled?: boolean
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  fieldProps,
  isMarginDisabled = false,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <span>
        <span
          className={clsx(styles.labelText, {
            [styles.labelTextMarginDisabled]: isMarginDisabled,
          })}
        >
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <CheckboxGroup
          onChange={(values): void => setFieldValue(name, values)}
          value={field.value}
          {...fieldProps}
        />
      </span>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* datepicker */
interface DatePickerFieldProps extends FieldProps {
  fieldProps?: DatePickerProps
}

export const DatePickerField: React.FC<DatePickerFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  fieldProps,
}) => {
  const [field, meta, helpers] = useField(name)

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Form.Item help={name === 'due_date' ? helperText : null}>
          <DatePicker
            onChange={(value): void => helpers.setValue(value)}
            value={field.value ? new Date(field.value) : undefined}
            onBlur={(): void => helpers.setTouched(true)}
            {...fieldProps}
          />
        </Form.Item>
      </label>
      {helperText && name !== 'due_date' && (
        <span className={styles.helperText}>{helperText}</span>
      )}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* rangepicker */
interface RangePickerFieldProps extends FieldProps {
  fieldProps: any
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
}

export const RangePickerField: React.FC<RangePickerFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  helperText,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <RangePicker
          onChange={(value: any): void => setFieldValue(name, value)}
          value={field.value}
          onBlur={(): void => setFieldTouched(name, true)}
          {...fieldProps}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* file */
interface FileUploadFieldProps extends FieldProps {
  fieldProps: FileUploadProps
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
}

export const FileUploadField = ({
  className = '',
  name,
  label,
  helperText,
  required,
  setFieldValue,
  fieldProps,
}: FileUploadFieldProps): React.ReactElement => {
  const [field, meta] = useField(name)
  const fieldValue = useMemo(() => {
    if (!Array.isArray(field.value)) return []

    return field.value.filter((item: any) => item.tag !== 'other_information')
  }, [field.value])
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
      </label>
      <FileUpload
        {...fieldProps}
        handleFiles={(files): void => setFieldValue(name, files)}
        defaultFileList={fieldValue}
      />
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* social */
interface AddLinkFieldProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  setFieldTouched: (n: string, v: boolean) => void
  fieldProps?: InputProps
  showIcon?: boolean
}

export const AddLinkField: React.FC<AddLinkFieldProps> = ({
  className = '',
  label,
  name,
  showError = true,
  required,
  setFieldValue,
  setFieldTouched,
  fieldProps,
  showIcon = false,
}) => {
  const [field, meta] = useField(name)
  const [linkCount, setLinkCount] = useState(field.value?.length ?? 0)

  useEffect(() => {
    setLinkCount(field.value?.length ?? 0)
  }, [field.value])

  const removeField = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ): void => {
    setLinkCount(linkCount - 1)
    arrayHelpers.remove(index)
  }

  const getSocialIcon = (link: string): React.ReactNode => {
    const props = { className: styles.socialPrefixIcon }
    if (link.includes('facebook.com')) return <FacebookFilled {...props} />
    if (link.includes('linkedin.com')) return <LinkedinFilled {...props} />
    if (link.includes('twitter.com')) return <TwitterSquareFilled {...props} />
    if (link.includes('instagram.com')) return <InstagramFilled {...props} />
    else return <span style={{ width: 16 }}></span>
  }

  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
      </label>

      <Button
        className={styles.addLinkBtn}
        icon={<PlusOutlined />}
        onClick={(): void => setLinkCount(linkCount + 1)}
        type="link"
        size="small"
        disabled={fieldProps?.disabled}
      >
        Add a URL
      </Button>

      <div className={styles.AddLinkInputField}>
        <FieldArray
          name={`${name}`}
          render={(arrayHelpers): React.ReactNode => (
            <>
              {[...Array(linkCount)].map((_, index) => (
                <Input
                  className={styles.socialField}
                  prefix={
                    showIcon ? getSocialIcon(field.value[index] ?? '') : null
                  }
                  onBlur={(): void => setFieldTouched(name, true)}
                  suffix={
                    <DeleteOutlined
                      className={styles.socialSuffixIcon}
                      onClick={(): void => removeField(index, arrayHelpers)}
                    />
                  }
                  value={field.value[index]}
                  onChange={(e): void =>
                    setFieldValue(`${name}.${index}`, e.target.value)
                  }
                  key={index}
                  {...fieldProps}
                />
              ))}
            </>
          )}
        />
      </div>

      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* Block Selection */

interface BlockSelectProps extends FieldProps {
  options: {
    id: number
    icon: string
    title: string
    suffix?: string | React.ReactNode
  }[]
  disabled?: boolean
  fieldProps: any
}

export const BlockSelect: React.FC<BlockSelectProps> = ({
  showError = true,
  label,
  required,
  fieldProps,
  options,
  disabled,
  name,
}) => {
  const [field, meta] = useField(name)

  return (
    <div>
      <div>
        <label>
          <span className={styles.labelText}>
            {label}
            {required && <span className={styles.requiredText}>*</span>}
          </span>
        </label>
        <div className={styles.blocksSelection}>
          {options.map(option => (
            <label key={option.id}>
              <div
                className={clsx(
                  styles.verticalBlock,
                  parseInt(field.value) === option.id &&
                    styles.activeVerticalBlock,
                  disabled ? styles.disabledVerticalBlock : ''
                )}
              >
                <div>
                  <img src={BlogContentVerticalIcon} alt="blog" />
                </div>
                <p className={styles.verticalBlogTitle}>{option.title}</p>
              </div>
              <input
                className={styles.visiblityHidden}
                {...field}
                type="radio"
                value={option.id}
                {...fieldProps}
                disabled={disabled}
              />
            </label>
          ))}
        </div>
      </div>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/* Slider */
interface SliderProps extends FieldProps {
  setFieldValue: (n: string, v: any) => void
  fieldProps: any
}
export const Slider: React.FC<SliderProps> = ({
  className = '',
  label,
  name,
  showError = true,
  required,
  setFieldValue,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''

  const onChange = (value: number[]): void => {
    setFieldValue(name, value)
  }

  return (
    <div className={`${className}`}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <Select
          value="male"
          options={[{ label: 'Male', value: 'male' }]}
          bordered={false}
        />
        <div className={styles.rangeTitle}>Age Range</div>
        <AntSlider
          range={true}
          value={field.value}
          onChange={onChange}
          className={`${errorClass}`}
          {...fieldProps}
        />
      </label>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

export const SwitchField: React.FC<FieldProps> = ({
  label,
  name,
  showError = true,
  required,
  disabled = false,
  showSwitchOptions,
  className,
  additionalClassName,
}) => {
  const [field, meta, helpers] = useField<boolean>(name)

  return (
    <div>
      <label className={className}>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
        </span>
        <div className={`${styles.metaSwitch} ${additionalClassName}`}>
          {showSwitchOptions && <p>No</p>}
          <Switch
            className={styles.switchBtn}
            size="small"
            checked={field.value}
            onChange={value => helpers.setValue(value)}
            disabled={disabled}
          />
          {showSwitchOptions && <p>Yes</p>}
        </div>

        {showError && meta.touched && meta.error ? (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
          </div>
        ) : null}
      </label>
    </div>
  )
}

/* tags */
export const TagsField: React.FC<FieldProps> = ({ name }) => {
  const [field, , helpers] = useField<string[]>(name)

  const onRemoveTag = (tag: string): void => {
    helpers.setValue(field.value.filter(item => !(item === tag)))
  }

  // remove tag items that are already selected
  const options = Object.values(TAGS)
    .filter(tag => !field.value.includes(tag.id))
    .map(tag => ({
      value: tag.id,
      label: tag.title,
    }))

  return (
    <div className={styles.tagsField}>
      {(field.value as TagType[]).map(tag => (
        <Tag
          key={tag}
          color={TAGS[tag].color}
          closable={true}
          onClose={(): void => onRemoveTag(tag)}
        >
          {TAGS[tag].title}
        </Tag>
      ))}

      {options.length > 0 && (
        <Select
          className={styles.tagsFieldSelect}
          size="small"
          options={options}
          onChange={(value): void => helpers.setValue([...field.value, value])}
          value={''}
          notFoundContent={null}
        />
      )}
    </div>
  )
}

/** Tag Select */
interface TagMultiSelectProps extends FieldProps {
  options: {
    id: number | string
    title: string
    color?: string
    icon?: string
  }[]
  showIcon?: boolean
}

export const TagMultiSelect: React.FC<TagMultiSelectProps> = ({
  showError = true,
  label,
  required,
  options,
  name,
  showIcon = false,
  className,
}) => {
  const [field, meta, helper] = useField(name)
  return (
    <div className={className}>
      <div>
        {label ? (
          <label>
            <span className={styles.labelText}>
              {label}
              {required && <span className={styles.requiredText}>*</span>}
            </span>
          </label>
        ) : (
          ''
        )}
        <div
          id="tagBlockSelection"
          className={
            showIcon ? styles.iconBlocksSelection : styles.tagBlocksSelection
          }
        >
          {options.map(option => (
            <label key={option.id} className={styles.tagBlock}>
              {showIcon ? (
                <div
                  className={clsx(
                    styles.iconBlock,
                    field?.value?.includes(option.id.toString()) &&
                      styles.activeTag
                  )}
                >
                  <img src={option.icon} alt={option.title} />
                  <p className={styles.iconTitle}>{option.title}</p>
                </div>
              ) : (
                <>
                  <Tag
                    color={
                      field?.value?.includes(option.id.toString())
                        ? option.color
                        : 'default'
                    }
                  >
                    {option.title}
                  </Tag>
                </>
              )}
              <input
                className={styles.visibilityHidden}
                {...field}
                onChange={(e): void => {
                  const i = field?.value?.findIndex(
                    (val: string) => val === e.target.value
                  )
                  if (i === -1) {
                    helper.setValue([...field.value, e.target.value])
                  } else {
                    helper.setValue(
                      field.value.filter(
                        (val: string) => val !== e.target.value
                      )
                    )
                  }
                }}
                type="checkbox"
                value={option.id.toString()}
              />
            </label>
          ))}
          {showError && meta.touched && meta.error ? (
            <div className={styles.error}>
              <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

/* select field */
interface CustomSelectFieldProps extends FieldProps {
  setFieldValue: (name: string, value: any) => void
  setFieldTouched: (name: string, value: boolean) => void
  fieldProps: SelectDropdownGroupProps
}

export const CustomSelectField: React.FC<CustomSelectFieldProps> = ({
  className = '',
  label,
  name,
  required,
  showError = true,
  helperText,
  setFieldValue,
  setFieldTouched,
  fieldProps,
}) => {
  const [field, meta] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''

  return (
    <div className={`${className} ${styles.selectField}`}>
      <label>
        <span className={styles.labelText}>
          {label} {required && <span className={styles.requiredText}>*</span>}
        </span>
        <CustomSelectDropdown
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={(value): void => setFieldValue(name, value)}
          onBlur={(): void => setFieldTouched(name, true)}
          value={field.value}
          {...fieldProps}
          optionsList={fieldProps.optionsList}
          disabled={fieldProps.disabled}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

interface SelectFieldProps extends FieldProps {
  fieldProps: SelectProps
}

export const AddOptionInSelect: React.FC<SelectFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  showError = true,
  required,
  fieldProps,
}) => {
  const [field, meta, helpers] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  const [newOptions, setNewOptions] = useState(fieldProps.options ?? [])

  const handleAddItem = (text: string) => {
    const _addOptions = [...newOptions, { label: text, value: text }]
    setNewOptions(_addOptions)
  }

  return (
    <div className={`${className} ${styles.selectField}`} data-cy={name}>
      <label>
        <span className={styles.labelText}>
          {label} {required && <span className={styles.requiredText}>*</span>}
        </span>
        <AddInSelect
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={(value: any): void => helpers.setValue(value)}
          onBlur={(): void => helpers.setTouched(true)}
          value={field.value}
          options={newOptions}
          addItem={handleAddItem}
          disabled={fieldProps.disabled}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

/** Multi block Select */
interface MultiSelectBlockProps extends FieldProps {
  options: {
    id: number | string
    title: string
    color?: string
    icon?: string
    type?: string
    full_size_src?: string
  }[]
  showIcon?: boolean
  onClick: (e: MouseEvent<HTMLInputElement>) => void
  popoverInfo?: string
  showPreview?: boolean
  disabled?: boolean
  fieldProps?: any
}

export const MultiSelectBlock: React.FC<MultiSelectBlockProps> = ({
  showError = true,
  label,
  required,
  options,
  name,
  showIcon = false,
  popoverInfo,
  onClick,
  showPreview = true,
  fieldProps,
  disabled,
}) => {
  const [field, meta, helper] = useField(name)
  const [showFullImage, setShowFullImage] = useState<{
    visible: boolean
    option?: {
      id: number | string
      title: string
      color?: string
      icon?: string
      type?: string
      full_size_src?: string
    } | null
  }>({ visible: false, option: null })

  return (
    <div>
      <Modal
        visible={showFullImage.visible}
        footer={null}
        onCancel={() => setShowFullImage({ visible: false, option: null })}
        closable
        width={630}
      >
        <img
          loading="lazy"
          alt={showFullImage.option?.title}
          src={showFullImage.option?.full_size_src}
          className={styles.previewImage}
        />
        <div className={styles.imageSrcContainer}>
          <span>View Image Source:</span>
          <div className={styles.imageSrcContainer}>
            <a
              href={showFullImage.option?.full_size_src}
              target="_blank"
              rel="noreferrer"
            >
              {showFullImage.option?.full_size_src}
            </a>
          </div>
        </div>
      </Modal>
      <div>
        {label ? (
          <label>
            <span className={styles.labelText}>
              {label}
              {required && <span className={styles.requiredText}>*</span>}
              {popoverInfo && (
                <PopOver content={popoverInfo}>
                  <InfoCircleFilled className={styles.infoIcon} />
                </PopOver>
              )}
            </span>
          </label>
        ) : (
          ''
        )}

        <div className={styles.ColourBlocksSelection}>
          {options.map(option => (
            <label
              key={option.id}
              className={clsx(
                styles.colorBlock,
                field.value?.includes(option.id) && styles.colourBlockActive,
                disabled ? styles.disabledVerticalBlock : ''
              )}
            >
              <div>
                {field.value?.includes(`${option.id}`) ? (
                  <CheckCircleFilled className={styles.verticalBlockCheck} />
                ) : null}
                <img src={option.icon} alt={option.title} />
              </div>

              {showPreview && (
                <div>
                  <EyeOutlined
                    className={styles.previewIcon}
                    onClick={() =>
                      setShowFullImage({ visible: true, option: option })
                    }
                  />
                </div>
              )}

              <input
                className={styles.visibilityHidden}
                {...field}
                onChange={(e): void => {
                  if (!Array.isArray(field.value)) return

                  const i = field?.value?.findIndex(
                    (val: string) => val === e.target.value
                  )
                  if (i === -1) {
                    helper.setValue([...field.value, e.target.value])
                  } else {
                    helper.setValue(
                      field.value.filter(
                        (val: string) => val !== e.target.value
                      )
                    )
                  }
                }}
                onClick={onClick}
                type="checkbox"
                value={option.id.toString()}
                disabled={disabled}
              />
            </label>
          ))}
        </div>
      </div>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

interface AddMultipleCustomColorsProps extends FieldProps {
  setFieldValue: (name: string, value: any) => void

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: any
  hideCustomColorLabel?: string
}

export const AddMultipleCustomColors: React.FC<AddMultipleCustomColorsProps> =
  ({
    className = '',
    label = 'Enter Color Hex Code',
    name,
    showError = true,
    required,
    setFieldValue,
    hideCustomColorLabel = false,
    fieldProps,
  }) => {
    const [field, meta] = useField(name)
    const [linkCount, setLinkCount] = useState(field.value?.length ?? 1)
    const [showField, setShowField] = useState<boolean>(false)

    useEffect(() => {
      setLinkCount(field.value?.length ?? 1)
    }, [field.value])

    const removeField = (
      index: number,
      arrayHelpers: FieldArrayRenderProps
    ): void => {
      setLinkCount(linkCount - 1)
      arrayHelpers.remove(index)
    }

    useEffect(() => {
      if (hideCustomColorLabel) {
        setShowField(true)
      }
    }, [hideCustomColorLabel])

    return (
      <div className={className}>
        {!hideCustomColorLabel && (
          <div
            onClick={() => setShowField(true)}
            onKeyPress={() => setShowField(true)}
            tabIndex={0}
            role="button"
            className={styles.customColorContainer}
          >
            <span className={styles.specificColorLabel}>
              {label}
              {required && <span className={styles.requiredText}>*</span>}
            </span>
          </div>
        )}

        {showField ? (
          <div
            id="field-block-container"
            className={styles.fieldBlockContainer}
          >
            <div className={styles.labelText}>{label}</div>
            <div>
              <FieldArray
                name={`${name}`}
                render={(arrayHelpers): React.ReactNode => (
                  <>
                    {[...Array(linkCount)].map((_: any, index: number) => (
                      <div
                        key={index}
                        className={styles.dimensionFieldContainer}
                      >
                        <div className={styles.inputContainer}>
                          <Input
                            {...fieldProps}
                            className={styles.colorPickerInputField}
                            type="color"
                            value={
                              field.value
                                ? `#${field.value[index]?.slice(1)}`
                                : '#'
                            }
                            onChange={(e): void => {
                              setFieldValue(`${name}.${index}`, e.target.value)
                            }}
                          />
                          <Input
                            value={field.value ? field.value[index] : ''}
                            onChange={(e): void => {
                              let _value = e.target.value
                              if (_value.indexOf('#') === -1) {
                                _value = `#${e.target.value}`
                              }
                              setFieldValue(`${name}.${index}`, _value)
                            }}
                            placeholder="#ff0000"
                            key={index}
                            {...fieldProps}
                          />
                          <div>
                            <DeleteOutlined
                              className={styles.deleteDimensionIcon}
                              onClick={(): void =>
                                removeField(index, arrayHelpers)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              />
            </div>

            <Button
              className={styles.addLinkBtn}
              icon={<PlusOutlined />}
              onClick={(): void => setLinkCount(linkCount + 1)}
              type="link"
              size="small"
              disabled={fieldProps.disabled}
            >
              Add Color
            </Button>
          </div>
        ) : null}

        {showError && meta.touched && meta.error ? (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
          </div>
        ) : null}
      </div>
    )
  }

/* Block Selection */

interface ColorBlockSelectProps extends FieldProps {
  pageSize?: number
  showBrowseMoreLink?: boolean
  popoverInfo?: string
  options: {
    id: number | string
    icon: string
    title: string
    suffix?: string | React.ReactNode
    disabled?: boolean
    pageSize?: number
    staticIcon?: React.ReactNode | string
    icon_set?: {
      blue: string
      default?: string
      gray?: string
    }
    tag?: string
  }[]
  onSelect?: (selectedOption: number | string) => void
  hasHoverEffect: boolean
}

export const ColorBlocks: React.FC<ColorBlockSelectProps> = ({
  showError = true,
  label,
  required,
  showBrowseMoreLink,
  options,
  name,
  pageSize = 10,
  onSelect,
  hasHoverEffect = false,
  popoverInfo,
}) => {
  const [field, meta, helpers] = useField(name)
  const [totalItemsToShow, setTotalItemsToShow] = useState<number>(pageSize)

  return (
    <div>
      <div>
        <label>
          <span className={styles.labelText}>
            {label}
            {required && <span className={styles.requiredText}>*</span>}
            {popoverInfo && (
              <PopOver content={popoverInfo}>
                <InfoCircleFilled className={styles.infoIcon} />
              </PopOver>
            )}
          </span>
        </label>
        <div className={styles.ColourBlocksSelection} data-cy={name}>
          {options.map(option => (
            <div key={option.id}>
              <label
                className={clsx(
                  styles.colorBlock,
                  { [styles.box]: hasHoverEffect },
                  field.value === option.id && styles.colourBlockActive,

                  {
                    [styles.disabled]: option.disabled,
                  }
                )}
                {...(field.value === option.id && {
                  'data-cy': `${name}-active`,
                })}
              >
                {field.value === option.id ? (
                  <CheckCircleFilled className={styles.verticalBlockCheck} />
                ) : null}

                <div>
                  <img
                    src={
                      option.icon_set?.gray
                        ? option.icon_set?.gray
                        : option.icon
                    }
                    alt={option.title}
                  />

                  {hasHoverEffect && (
                    <div className={styles.hoverTitleBlock}>
                      <span>{option.title}</span>
                    </div>
                  )}

                  <input
                    className={styles.visiblityHidden}
                    {...field}
                    onChange={(): void => {
                      if (typeof onSelect === 'function') onSelect(option.id)

                      helpers.setValue(option.id)
                    }}
                    type="radio"
                    value={option.id}
                    disabled={option.disabled}
                  />
                </div>
              </label>
              {!hasHoverEffect && (
                <p className={styles.colorBlockTitle}>{option.title}</p>
              )}
            </div>
          ))}
        </div>
        {showBrowseMoreLink ? (
          <Button
            className={styles.blockSelect__button}
            onClick={() =>
              setTotalItemsToShow(p => {
                if (options.length <= totalItemsToShow) return pageSize
                else return p + pageSize
              })
            }
            size={'small'}
            type="link"
          >
            {`Browse ${options.length <= totalItemsToShow ? 'Less' : 'More'}`}
          </Button>
        ) : null}
      </div>
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

interface SelectFieldProps extends FieldProps {
  fieldProps: SelectProps
}

export const AddDimensionSelect: React.FC<SelectFieldProps> = ({
  className = '',
  label,
  name,
  helperText,
  showError = true,
  required,
  fieldProps,
}) => {
  const [field, meta, helpers] = useField(name)
  const errorClass = meta.touched && meta.error ? styles.borderRed : ''
  const [newOptions, setNewOptions] = useState(fieldProps.options ?? [])

  const handleAddItem = (values: { width: string; height: string }) => {
    const _displayLabel = `Custom (${values.width} X ${values.height}) px`
    const _addOptions = [
      ...newOptions,
      { label: _displayLabel, value: _displayLabel },
    ]
    setNewOptions(_addOptions)
  }

  return (
    <div className={`${className} ${styles.selectField}`} data-cy={name}>
      <label>
        <span className={styles.labelText}>
          {label} {required && <span className={styles.requiredText}>*</span>}
        </span>
        <DimensionsSelect
          className={`w-100 ${errorClass}`}
          optionFilterProp="label"
          onChange={(value: any): void => helpers.setValue(value)}
          onBlur={(): void => helpers.setTouched(true)}
          value={field.value}
          options={newOptions}
          addItem={handleAddItem}
          disabled={fieldProps.disabled}
        />
      </label>
      {helperText && <span className={styles.helperText}>{helperText}</span>}
      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

interface AddDimensions extends FieldProps {
  setFieldValue: (n: string, v: { width: string; height: string }[]) => void
  setFieldTouched: (n: string, v: boolean) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: any
  showIcon?: boolean
  popoverInfo?: string
}

export const DimensionsField: React.FC<AddDimensions> = ({
  className = '',
  label,
  name,
  showError = true,
  required,
  setFieldValue,
  fieldProps,
  popoverInfo = '',
}) => {
  const [field, meta, helpers] = useField(name)
  const [linkCount, setLinkCount] = useState(field.value?.length ?? 0)
  useEffect(() => {
    setLinkCount(field.value?.length ?? 0)
  }, [field.value])

  const removeField = (
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ): void => {
    setLinkCount(linkCount - 1)
    arrayHelpers.remove(index)
  }
  return (
    <div className={className}>
      <label>
        <span className={styles.labelText}>
          {label}
          {required && <span className={styles.requiredText}>*</span>}
          {popoverInfo && (
            <PopOver content={popoverInfo}>
              <InfoCircleFilled className={styles.infoIcon} />
            </PopOver>
          )}
        </span>
      </label>

      <div>
        <FieldArray
          name={`${name}`}
          render={(arrayHelpers): React.ReactNode => (
            <>
              {field.value?.map((_: any, index: number) => (
                <div key={index} className={styles.dimensionFieldContainer}>
                  <div className={styles.inputContainer}>
                    <Input
                      value={
                        field.value[index]?.width
                          ? field.value[index]?.width
                          : ''
                      }
                      placeholder="Enter Width"
                      onChange={(e): void => {
                        const _value = [...field.value]
                        _value[index] = {
                          ..._value[index],
                          width: e.target.value,
                        }
                        helpers.setValue(_value)
                      }}
                      {...fieldProps}
                      disabled={fieldProps?.disabled}
                    />
                    <div>X</div>
                    <Input
                      value={
                        field.value[index]?.height
                          ? field.value[index]?.height
                          : ''
                      }
                      placeholder="Enter Height"
                      onChange={(e): void => {
                        const _value = [...field.value]
                        _value[index] = {
                          ..._value[index],
                          height: e.target.value,
                        }
                        helpers.setValue(_value)
                      }}
                      key={index}
                      {...fieldProps}
                      disabled={fieldProps?.disabled}
                    />
                    <div>px</div>
                  </div>
                  <div className={styles.dimensionsAction}>
                    <div>
                      <DeleteOutlined
                        className={styles.deleteDimensionIcon}
                        onClick={(): void => removeField(index, arrayHelpers)}
                        disabled={fieldProps?.disabled}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        />
      </div>

      <Button
        type="link"
        icon={<PlusOutlined />}
        disabled={fieldProps?.disabled}
        onClick={(): void =>
          setFieldValue(name, [...field.value, { width: '', height: '' }])
        }
      >
        Add More
      </Button>

      {showError && meta.touched && meta.error ? (
        <div className={styles.error}>
          <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
        </div>
      ) : null}
    </div>
  )
}

interface CustomSelectSearchProps extends FieldProps {
  actions: FormikHelpers<any>
  miniTooltip?: string
  fieldProps?: any
}

let blurTimeout: NodeJS.Timeout

export const CustomSelectSearch: React.FC<CustomSelectSearchProps> = ({
  name,
  label,
  required,
  actions,
  miniTooltip,
  fieldProps,
}) => {
  const inputRef: any = useRef<any>(null)
  const options = fieldProps?.options as TemplateDetails[]
  const createNewTemplate = useModal('create-new-template')
  const [field, meta, helpers] = useField(name)
  const [showList, setShowList] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const filteredOptions = searchInput
    ? options?.filter(data => data?.template_name?.includes(searchInput))
    : options

  const orgTemplates = filteredOptions?.filter(data => data?.company) ?? []
  const pepperTemplates = filteredOptions?.filter(
    data => data?.is_published === 1 && !data?.company
  )
  const teamTemplates = filteredOptions?.filter(
    data => data?.is_published !== 1 && !data?.company
  )

  const getTemplatesList = (templatesData: TemplateDetails[], type: string) => {
    return {
      key: type,
      list: templatesData?.map(item => {
        return {
          header: item?.template_name,
          subHeader: `created by ${item?.creator?.first_name} ${item?.creator?.last_name}`,
          id: item?.template_code,
        }
      }),
    }
  }

  const list = [
    getTemplatesList(orgTemplates, 'Organization Templates'),
    getTemplatesList(pepperTemplates, 'Pepper Templates'),
    getTemplatesList(teamTemplates, 'Team Templates'),
  ]

  useEffect(() => {
    if (field.value) {
      const selectedTemplateName = options?.find(
        (data: any) =>
          data?.template_code === field.value || data?.id === field.value
      )
      selectedTemplateName?.template_name &&
        setSearchInput(selectedTemplateName?.template_name)
    }
  }, [field.value, options])

  const updateInput = (id: string | number, name?: string) => {
    helpers.setValue(id)
    name && setSearchInput(name)
  }

  return (
    <>
      <div className={styles.searchInput}>
        <label>
          <span className={styles.inputLabelV2}>
            {label}

            {required && <span className={styles.requiredText}>*</span>}
            {miniTooltip && (
              <Tooltip
                placement="top"
                title={() => (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: miniTooltip,
                    }}
                  />
                )}
              >
                <TooltipInfo className={styles.tooltipPos} />
              </Tooltip>
            )}
          </span>
        </label>
        <Input
          itemRef={inputRef}
          disabled={fieldProps?.disabled}
          className={clsx(styles.inputAreaV2, {
            [styles.borderRed]: meta.touched && meta.error,
          })}
          value={searchInput}
          onFocus={() => {
            clearTimeout(blurTimeout)
            setShowList(true)
          }}
          onBlur={() => {
            blurTimeout = setTimeout(() => {
              helpers.setTouched(true)
              const selectedTemplate = options?.find(
                (data: any) => data?.template_name === searchInput
              )
              if (!selectedTemplate) {
                setSearchInput('')
                helpers.setValue('')
              } else {
                helpers.setValue(selectedTemplate?.template_code)
              }
              setShowList(false)
            }, 500)
          }}
          onChange={e => setSearchInput(e.target.value)}
          suffix={
            !showList ? (
              <DownOutlined className={styles.templateSearchIcon} />
            ) : (
              <SearchOutlined className={styles.templateSearchIcon} />
            )
          }
        />
        {showList && (
          <div className={styles.templateListWrap}>
            <div className={styles.csForeground}>
              <div className={styles.templateListOverlayWrap}>
                <TemplateList
                  list={list}
                  width={fieldProps?.width}
                  submitButtonText={'Create New Template'}
                  listImage={
                    <TemplateIcon className={styles.templateImageInList} />
                  }
                  handleListItemClick={(val, details) => {
                    updateInput(val, details)
                    inputRef?.current?.focus({
                      cursor: 'start',
                    })
                    clearTimeout(blurTimeout)
                  }}
                  handleSubmitClick={() => {
                    fieldProps?.additionalClose?.()
                    createNewTemplate.show()
                    setShowList(false)
                  }}
                  plusImage={<PlusIcon />}
                  emptyTemplateImg={<EmptyIcon />}
                  imgHeaderText="Create a new Template"
                  imgBottomText="Click on “Create new template” to create a new template"
                />
              </div>
            </div>
          </div>
        )}
        {meta.touched && meta.error ? (
          <div className={styles.error}>
            <CloseCircleFilled className={styles.errorIcon} /> {meta.error}
          </div>
        ) : null}
      </div>
    </>
  )
}
